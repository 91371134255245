.App {
  text-align: center;
}

.App-header {
  background-color: snow;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.introBox {
  height: 100%;
  margin-top: 10px;
  width: 80%;
  height: 100%;
}

.fade-in {
  opacity: 0;
}
.fade-in.visible {
  transition: opacity 0.5s ease-in;
  opacity: 1;
}

a {
  color: black;
}

green {
  color: darkGreen;
}

#nounderline {
  text-decoration: none;
}

#introbox {
  align-content: center;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
